import React, { useState } from "react";
import { navigate } from "gatsby";

const encode = (data) =>
  Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");

const ContactForm = () => {
  const [formData, setFormData] = useState({});
  const enableSubmitBtn = formData.name && formData.email && formData.message;

  const handleSubmit = (event) => {
    event.preventDefault();

    if (enableSubmitBtn) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": event.target.getAttribute("name"),
          ...formData,
        }),
      })
        .then(() => navigate("/thank-you/"))
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <form
      method="post"
      netlify-honeypot="bot-field"
      data-netlify="true"
      name="contact"
      action="/"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <div className="my-4">
        <label
          className="text-lg text-gray-800 dark:text-gray-300"
          htmlFor="name"
        >
          Name
        </label>
        <input
          className="w-full border border-gray-300 rounded p-2 outline-none focus:outline-none focus:ring-2 focus:ring-primary-600 focus:border-transparent dark:bg-primary-900"
          id="name"
          name="name"
          placeholder="Enter your name here"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
      </div>

      <div className="my-4">
        <label
          className="text-lg text-gray-800 dark:text-gray-300"
          htmlFor="email"
        >
          Email
        </label>
        <input
          className="w-full border border-gray-300 rounded p-2 outline-none focus:outline-none focus:ring-2 focus:ring-primary-600 focus:border-transparent dark:bg-primary-900"
          id="email"
          name="email"
          placeholder="Enter your email address"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
      </div>

      <div className="my-4">
        <label
          className="text-lg text-gray-800 dark:text-gray-300"
          htmlFor="message"
        >
          Message
        </label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={(e) =>
            setFormData({ ...formData, message: e.target.value })
          }
          className="w-full border border-gray-300 rounded p-2 outline-none focus:outline-none focus:ring-2 focus:ring-primary-600 focus:border-transparent dark:bg-primary-900"
        ></textarea>
      </div>
      {enableSubmitBtn ? (
        <button className="bg-primary-500 text-primary-50 rounded py-3 px-4 w-full hover:bg-primary-600">
          Send Message
        </button>
      ) : (
        <button
          disabled
          className="bg-primary-500 text-primary-50 rounded py-3 px-4 w-full opacity-50"
        >
          Send Message
        </button>
      )}
    </form>
  );
};

export default ContactForm;
