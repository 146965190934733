import React from "react";

import Layout from "../components/Layout";
import ContactForm from "../components/ContactForm";

import hero from "../images/hero.png";
import upload from "../images/Upload-Data.png";
import answerQns from "../images/Answer-a-few-questions.png";
import review from "../images/Review.png";

import noTax from "../images/01_No-Tax-compressed.png";
import stepByStep from "../images/02_Step-by-step-compressed.jpg";
import retrieveRecords from "../images/03_Retrieve-records-compressed.jpg";

import Currency from "../icons/currency.svg";
import Database from "../icons/database.svg";
import Phone from "../icons/phone.svg";
import ThumbsUp from "../icons/thumbsUp.svg";
import Check from "../icons/check.svg";

const IndexPage = ({ path }) => {
  return (
    <Layout currentPath={path} title="Home">
      <section className="bg-primary-500 px-4 pt-8 dark:bg-gray-900">
        <div className="container mx-auto">
          <p className="my-4 text-5xl text-center text-white dark:text-primary-100">
            Painless Tax Filing for Singapore's SMEs
          </p>
          <p className="my-6 text-lg text-white dark:text-gray-300">
            Easy, hassle-free and fast. Convert your accounting profit to
            taxable profit in 3 simple steps within 10 minutes.
          </p>
          <div className="text-center py-2">
            <a
              href="https://app.tinkertax.com/users/sign_up"
              className="bg-white text-primary-500 rounded py-4 px-10 font-bold"
            >
              Try Now
            </a>
          </div>
          <div className="pt-10">
            <img src={hero} alt="tinkertax-hero" />
          </div>
        </div>
      </section>
      <section className="pt-12 px-4 container mx-auto">
        <h2 className="text-3xl font-extralight font-serif text-center">
          How It Works?
        </h2>
        <p className="text-center text-gray-600 text-lg">
          Convert your accounting data to tax in 3 steps
        </p>
        <div className="my-12 flex flex-wrap">
          <div className="px-2 my-6 w-full md:w-1/3 md:my-2">
            <img src={upload} alt="Upload Data" />
            <p className="font-semibold text-gray-700">
              Step 1: Upload or Enter your Accounting Data
            </p>
            <p className="text-gray-500">
              Upload your trial balance using Microsoft Excel, or key in your
              accounting data into our system.
            </p>
          </div>
          <div className="px-2 my-6 w-full md:w-1/3 md:my-2">
            <img src={answerQns} alt="Answer a few questions" />
            <p className="font-semibold text-gray-700">
              Step 2: Help Us Understand Your Data
            </p>
            <p className="text-gray-500">
              We will ask you a few questions based on your accounting data to
              help us understand the nature of your transactions. We also
              provide customised links to IRAS resources in case you need
              clarification.
            </p>
          </div>
          <div className="px-2 my-6 w-full md:w-1/3 md:my-2">
            <img src={review} alt="Review Tax computation" />
            <p className="font-semibold text-gray-700">
              Step 3: Review your Tax Computation and Form C-S Data
            </p>
            <p className="text-gray-500">
              We will list our tax treatment and assumptions all in one place
              and generate your tax computation for you. Review them and make
              changes if necessary with just one click.
            </p>
          </div>
        </div>
        <p className="text-center text-gray-600 text-lg">
          In 3 simple steps, you will have your full tax computation and tax
          form data ready for submission to IRAS!
        </p>
      </section>

      <section className="bg-gray-100">
        <section className="container mx-auto py-8">
          <div className="flex flex-wrap px-4">
            <div className="w-full md:w-1/2 px-2">
              <p className="text-3xl text-gray-600">
                You Don’t Need to Know Tax
              </p>
              <p className="text-gray-600">
                Not familiar with Singapore corporate tax regulations? No
                worries, leave this bit to us. Let us deal with the
                ever-changing rules while you focus on growing your business.
              </p>
              <ul className="list-none leading-tight text-gray-600 list-inside md:list-disc">
                <li className="my-4">
                  Answer easy questions about your business in plain language
                </li>
                <li className="my-4">
                  Get help via links to IRAS resources in relation to
                  your&nbsp;query
                </li>
                <li className="my-4">
                  Identify deductions and tax incentives that you qualify for
                </li>
                <li className="my-4">
                  Updated every year with the latest tax policies and incentives
                </li>
              </ul>
            </div>
            <div className="w-full md:w-1/2 px-2">
              <img src={noTax} alt="No tax knowledge needed" />
            </div>
          </div>
        </section>
      </section>

      <section className="bg-white">
        <section className="container mx-auto py-8">
          <div className="flex flex-wrap px-4">
            <div className="w-full md:w-1/2 px-2">
              <p className="text-3xl text-gray-600">
                Let us help you step by step
              </p>
              <ul className="list-none leading-tight text-gray-600 list-inside md:list-disc">
                <li className="my-4">Import your accounting records</li>
                <li className="my-4">
                  Walk you through the relevant accounting entries
                </li>
                <li className="my-4">
                  Help you accurately report and maximize your deductions
                </li>
                <li className="my-4">Complete within 10 minutes</li>
              </ul>
            </div>
            <div className="w-full md:w-1/2 px-2">
              <img src={stepByStep} alt="No tax knowledge needed" />
            </div>
          </div>
        </section>
      </section>

      <section className="bg-gray-100">
        <section className="container mx-auto py-8">
          <div className="flex flex-wrap px-4">
            <div className="w-full md:w-1/2 px-2">
              <p className="text-3xl text-gray-600">
                Keep and retrieve your records anytime
              </p>
              <ul className="list-none leading-tight text-gray-600 list-inside md:list-disc">
                <li className="my-4">
                  Keep track of your tax and accounting records all in one place
                </li>
                <li className="my-4">Retrieve these information any time</li>
                <li className="my-4">
                  Download your tax computation and tax form data for
                  safekeeping
                </li>
                <li className="my-4">
                  Great if you want to review your tax position
                </li>
              </ul>
            </div>
            <div className="w-full md:w-1/2 px-2">
              <img src={retrieveRecords} alt="No tax knowledge needed" />
            </div>
          </div>
        </section>
      </section>

      <section className="bg-white">
        <section className="container mx-auto py-8">
          <p className="text-3xl text-gray-600 font-serif text-center">
            Our Promises
          </p>

          <div className="flex flex-wrap py-8">
            <div className="w-full md:w-1/3 text-center text-gray-600">
              <div className="text-primary-500 flex justify-center">
                <Currency className="w-20" />
              </div>
              <p className="text-xl">No Hidden Costs</p>
              <p className="text-base">
                For the price you pay, you will be able to access all features
                that are available to our users for each year of assessment.
                There are no additional costs.
              </p>
            </div>

            <div className="w-full md:w-1/3 text-center text-gray-600">
              <div className="text-primary-500 flex justify-center">
                <Database className="w-20" />
              </div>
              <p className="text-xl">Data Security</p>
              <p className="text-base">
                We will protect your data from unauthorised access. Even we
                cannot access your data without your permission.
              </p>
            </div>

            <div className="w-full md:w-1/3 text-center text-gray-600">
              <div className="text-primary-500 flex justify-center">
                <Phone className="w-20" />
              </div>
              <p className="text-xl">Unlimited Free Support</p>
              <p className="text-base">
                Do not worry if there is a problem. We will always assist you
                via email and phone call if there is a need.
              </p>
            </div>
          </div>

          <div className="flex flex-wrap py-8">
            <div className="w-full md:w-1/2 text-center text-gray-600">
              <div className="text-primary-500 flex justify-center">
                <ThumbsUp className="w-20" />
              </div>
              <p className="text-xl">Satisfaction Guarantee</p>
              <p className="text-base">
                Try out our software. You do not have to pay if you are not
                satisfied.
              </p>
            </div>

            <div className="w-full md:w-1/2 text-center text-gray-600">
              <div className="text-primary-500 flex justify-center">
                <Check className="w-20" />
              </div>
              <p className="text-xl">Accuracy Guarantee</p>
              <p className="text-base">
                If you have to pay IRAS penalty due to Tinkertax’s software
                error, we will refund you the penalty and interest.
              </p>
            </div>
          </div>
        </section>
      </section>

      <section className="bg-white">
        <section className="container mx-auto py-8">
          <p className="text-3xl text-gray-600 font-serif text-center">
            Pricing
          </p>
          <p className="text-base text-center text-gray-600">
            If you are not satisfied, contact us within the first 14 days and
            we'll send you a full refund.
          </p>

          <div className="flex flex-wrap md:flex-nowrap">
            <div className="border border-gray-200 w-full md:w-1/2 mx-2 my-2 shadow">
              <div className="bg-primary-500 text-white text-center py-3">
                <span>Basic</span>
              </div>
              <div>
                <p className="text-2xl text-center">Free</p>
                <div className="border-t border-gray-300 text-gray-500 p-4">
                  Estimated Chargeable Income (ECI) Calculation
                </div>
                <div className="border-t border-gray-300 text-gray-500 p-4">
                  ECI Reminders
                </div>
                <div className="border-t border-gray-300 text-gray-500 p-4 text-center">
                  <a
                    href="https://app.tinkertax.com/users/sign_up"
                    className="bg-white border border-primary-500 text-primary-500 rounded py-2 px-10 font-bold"
                  >
                    Try Now
                  </a>
                </div>
              </div>
            </div>

            <div className="border border-gray-200 w-full md:w-1/2 mx-2 my-2 shadow">
              <div className="bg-primary-500 text-white text-center py-3">
                <span>Standard</span>
              </div>
              <div>
                <p className="text-2xl text-center">
                  <span className="text-4xl text-primary-500">S$79</span> / Year
                  of Assessment
                </p>
                <div className="border-t border-gray-300 text-gray-500 p-4">
                  Everything in Basic
                </div>
                <div className="border-t border-gray-300 text-gray-500 p-4">
                  Tax Returns (Form C-S) And Tax Computations Generated
                </div>
                <div className="border-t border-gray-300 text-gray-500 p-4 text-center">
                  <a
                    href="https://app.tinkertax.com/users/sign_up"
                    className="bg-white border border-primary-500 text-primary-500 rounded py-2 px-10 font-bold"
                  >
                    Try Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <p className="text-gray-600 px-4">
            When you click on “Try Now”, you will have access to all our
            features in our Basic plan. You will only pay when you want to see
            your tax returns (Form C-S) and tax computation.
          </p>
        </section>
      </section>

      <section className="bg-gray-100">
        <section className="px-4 py-8 container mx-auto">
          <h2 className="text-2xl text-center">Contact Us</h2>
          <p className="my-4 text-lg text-gray-700 dark:text-gray-300">
            Want to work with us? Leave your contact here and we will get back
            to you.
          </p>
          <ContactForm />
        </section>
      </section>
    </Layout>
  );
};

export default IndexPage;
